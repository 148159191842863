<template>
  <div class="download-in-app__post-detail">
    <loading :loading="pageStatus === 'loading'" />
    <page-error
      v-if="pageStatus === 'error'"
      :text="pageErrorText"
      :style="{ background: 'transparent' }"
    />
    <div v-else-if="pageStatus === 'normal'">
      <div class="download-in-app__post-detail__header">
        <div class="header-content">
          <div class="left">
            <img
              :src="groupInfo.iconUrl"
              class="group-icon"
              alt="group icon"
            >
          </div>
          <div class="main">
            <div class="group-name">
              {{ groupInfo.name }}
            </div>
            <div class="group-desc">
              {{ groupInfo.latestMessage }}
            </div>
          </div>
          <div class="right">
            <Button
              :size="'m'"
              @click="openGroup"
              type="primary"
            >
              进圈逛逛
            </Button>
          </div>
        </div>
      </div>
      <div class="download-in-app__post-detail__content">
        <h1 class="post-detail__title">
          {{ postInfo.title }}
        </h1>
        <div
          v-if="authorInfo"
          class="author-info"
        >
          <img
            :src="authorInfo.iconUrl"
            alt=""
            class="author-info__avatar"
          >
          <div class="author-info__main">
            <div class="nickname">
              {{ authorInfo.nickName }}
            </div>
            <div class="date">
              {{ postInfo.createTime | getRelativeTime }}
            </div>
          </div>
          <div class="author-info__right">
            {{ postInfo.readCnt | viewNumToText }}浏览
          </div>
        </div>
        <post-text :post-info="postInfo" />
        <div
          @click="openGroup"
          class="post-detail__bottom"
        >
          更多精彩内容-尽在腾讯游戏社区>>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 简略版本帖子页面
import Button from '@/ui/button';
import Loading from '@/ui/loading/index.vue';
import { launchApp } from '@/page/embed-page/download-in-app/util';
import {
  getPostInfo, getUserInfo, logEvent, readPostAction,
} from '../server';
import PostText from './post-text.vue';
import PageError from '../../../error-page/common-error/index.vue';

/**
 * 根据帖子信息返回上报的类型
 * @param {object} info
 * @return {number} contentType
 * */
export function getReportContentTypeByInfo(info) {
  let contentType = 0; // 默认未知
  // 帖子信息里
  switch (String(info.type)) {
    case '1':
      if (info.sub_type) {
        contentType = String(info.sub_type) === '2' ? 2 : 1;
      } else {
        // 兜底是普通帖子
        contentType = 1;
      }
      break;
    case '2':
      contentType = 3;
      break;
    case '3':
      contentType = 4;
      break;
    default:
      break;
  }
  return contentType;
}

export default {
  name: 'DownloadInAppPostDetail',
  components: {
    PostText,
    Button,
    PageError,
    Loading,
  },
  data() {
    return {
      pageStatus: 'loading', // loading, normal, error
      postId: '',
      postType: '',
      gameCode: '',
      postInfo: null,
      authorInfo: null,
      groupInfo: null,
      staytimeStart: new Date().getTime(),
    };
  },
  mounted() {
    const { postId, postType, gameCode } = this.$route.query;

    if (postId && postType && gameCode) {
      this.postId = postId;
      this.postType = postType;
      this.gameCode = gameCode;
      this.init();
    } else {
      this.pageStatus = 'error';
      this.pageErrorText = '对不起，页面链接有误';
    }
    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden) {
          this.reportStayTime();
        } else {
          this.staytimeStart = new Date().getTime();
        }
      },
      false,
    );
  },
  beforeDestroy() {
    this.reportStayTime();
  },
  methods: {
    init() {
      return Promise.all([getUserInfo(), this.getPostInfo()])
        .then(() => {
          this.reportExposure();
          // this.readPost(); // 暂不支持app外部上报
        })
        .catch(([err1, err2]) => {
          this.$logger.err('init error', err1, err2);
        });
    },
    getPostInfo() {
      this.pageStatus = 'loading';
      return getPostInfo({
        postId: Number(this.postId),
        postType: Number(this.postType),
      })
        .then((res) => {
          this.postInfo = res?.info?.[0]?.postInfo;
          this.authorInfo = res?.info?.[0].creator;
          this.groupInfo = res?.info?.[0].groupInfo;
          if (this.postInfo && this.authorInfo && this.groupInfo) {
            this.pageStatus = 'normal';
          } else {
            this.pageStatus = 'error';
          }
        })
        .catch(() => {
          this.pageStatus = 'error';
        });
    },

    openGroup() {
      // tgc://native?moduleName=groupdetail&groupId=${groupId}
      launchApp({
        scheme: `tgc://native?moduleName=groupdetail&groupId=${this.groupInfo.id}`,
        downloadUrlParams: {
          // 参数会带到下载页面
          shanxianinchannel: this.gameCode,
        },
      });

      logEvent({
        operId: '1904000010302',
        contentid: this.postInfo.id,
        contenttype: getReportContentTypeByInfo(this.postInfo),
      });
    },

    reportExposure() {
      if (this.postInfo) {
        // console.log('call reportExposure');
        logEvent({
          operId: '1904000010102',
          contentid: this.postInfo.id,
          contenttype: getReportContentTypeByInfo(this.postInfo),
        });
      }
    },

    reportStayTime() {
      const now = new Date().getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      if (staytime > 0 && staytime < 3600) {
        logEvent({
          operId: '1904000010602',
          staytime,
          contentid: this.postInfo.id,
          contenttype: getReportContentTypeByInfo(this.postInfo),
        });
      }
      this.staytimeStart = now;
    },

    // 帖子已读
    readPost() {
      if (this.postInfo) {
        readPostAction({
          postId: this.postInfo.id,
          postCreatorId: this.postInfo.creatorId,
          groupId: this.postInfo.groupId,
          postType: this.postInfo.type,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.download-in-app__post-detail {
  background-color: #0e1025;
  color: #ccc;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 100%;
  box-sizing: border-box;
}

// Begin 顶部
.download-in-app__post-detail__header {
  position: fixed;
  z-index: 10;
  height: 60px;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url(https://static.gameplus.qq.com/business/gameh5/topbar.jpg);
  background-size: 100% 100%;
  .header-content {
    max-width: 612px;
    height: 100%;
    margin: 0 auto;
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;

    .left {
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      align-items: center;
      .group-icon {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .main {
      flex: 1;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .group-name {
        font-size: 14px;
        color: #fff;
        line-height: 1.5em;
        font-weight: bold;
      }
      .group-desc {
        margin-top: 5px;
        font-size: 12px;
        color: #fff;
        opacity: 0.3;
      }
    }

    .right {
      //
    }
  }
}

// End 顶部

.download-in-app__post-detail__content {
  max-width: 612px;
  margin: 0 auto;
  padding-top: 15px;
  flex: 1;
  .author-info__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .post-detail__title {
    color: #fff;
    font-size: 20px;
    line-height: 1.5em;
    margin: 0 0 10px;
    padding: 0 18px;
  }

  .author-info {
    display: flex;
    flex-direction: row;
    padding: 0 18px;
    font-size: 14px;
    color: #ccc;

    .author-info__main {
      flex: 1;
      display: flex;
      flex-direction: row;
      .nickname {
        margin-left: 10px;
      }
      .date {
        margin-left: 15px;
      }
    }
  }
}

.download-in-app__post-detail {
  .post-detail__bottom {
    font-size: 16px;
    color: #8092FD;
    text-align: center;
    line-height: 16px;
    padding-top: 7px;
  }
}
</style>
