<template>
  <div class="download-in-app__post-text">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <article v-html="html" />
    <div class="img-list">
      <img
        v-for="(item, index) in imgList"
        :key="item.url + index"
        :src="item.url"
        class="img-item"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { filterXSS } from 'xss';

export default {
  name: 'DownloadInAppPostText',
  props: {
    postInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    html() {
      if (!this.postInfo) return '';
      const html = filterXSS(this.postInfo.content);
      return html;
    },
    imgList() {
      // 来自App端的发帖，需要手动在底部插入图片
      if (!this.postInfo) return [];
      if (this.postInfo.channel === 2) {
        const imgList = this.postInfo.pics?.info;
        return imgList;
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
.download-in-app__post-text {
  padding: 18px;
  font-size: 16px;

  line-height: 1.5em;;
  h1 {
    font-size: 2em;
    font-weight: bolder;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bolder;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bolder;
  }

  h4 {
    font-size: 1.12em;
    font-weight: bolder;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bolder;
  }

  h6 {
    font-size: 0.75em;
    font-weight: bolder;
  }

  strong {
    font-weight: bold;
  }

  p {
    min-height: 28px;
  }

  img {
    border-radius: 6px;
  }

  img,
  video {
    width: 100% !important;
    height: auto !important;
    display: block;
    border: none;
    margin: 10px 0;
  }
}

</style>
